import React, { Component } from 'react'
import './styles/Projects.css'

export default class Projects extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projects: [
        {
          img: './images/projects/Team_App.PNG',
          desc: 'A team and organizations collaboration software',
          link: '#',
        },
        {
          img: './images/projects/Chef_Me.PNG',
          desc: 'A Company Website-ChefMe',
          link: '#',
        },
        {
          img: './images/projects/Portfolio.PNG',
          desc: 'Rwanda Compassion Software',
          link: '#',
        },
        {
          img: './images/projects/mike-tinnion-3ym6i13Y9LU-unsplash.jpg',
          desc: 'Rwanda Compassion Software',
          link: '#',
        },
        {
          img: './images/projects/thisisengineering-raeng-64YrPKiguAE-unsplash.jpg',
          desc: 'A Company Website-ChefMe',
          link: '#',
        },
        {
          img: './images/projects/kenny-eliason-y_6rqStQBYQ-unsplash.jpg',
          desc: 'A team and organizations collaboration software',
          link: '#',
        },
      ],
      currentSlide: 0,
    }
  }

  componentDidMount() {
    this.slideInterval = setInterval(this.nextSlide, 3000)
  }

  componentWillUnmount() {
    clearInterval(this.slideInterval)
  }

  nextSlide = () => {
    this.setState((prevState) => ({
      currentSlide: (prevState.currentSlide + 1) % 4,
    }))
  }
  render() {
    const { projects, currentSlide } = this.state

    return (
      <div className='Projects' id='projects'>
        <section className='section projects is-medium is-white has-text-centered'>
          <div className='container is-narrow'>
            <h1 className='title is-spaced is-size-3-desktop is-size-4-mobile'>
              Our Recent Work
            </h1>
            <h2 className='subtitle is-size-5-desktop'>
              Here are a few design projects I've worked on recently. Want to
              see more? <a href='/#contact'>Email me</a>.
            </h2>
            <div className='project-grid'>
              <div className='columns is-multiline is-mobile'>
                {projects
                  .slice(currentSlide, currentSlide + 3)
                  .map((project, index) => (
                    <div
                      className={`column is-12-mobile is-half-tablet is-one-third-desktop`}
                      key={index}
                    >
                      {/* Render the project */}
                      <figure className='image is-3by2 has-border'>
                        <img
                          className='project-thumb'
                          src={project.img}
                          alt='project'
                        />
                        <figcaption>
                          <h1 className='title is-size-5 is-size-4-widescreen'>
                            {project.desc}
                          </h1>
                          <a
                            disabled={project.link === '#' ? true : false}
                            className='button is-primary is-outlined is-rounded'
                            href={project.link}
                            target='_blank'
                            rel='noreferrer'
                          >
                            <span>Visit Website</span>
                            <span className='icon'>
                              <i className='fas fa-angle-right'></i>
                            </span>
                          </a>
                        </figcaption>
                        <div className='overlay'></div>
                      </figure>
                    </div>
                  ))}
              </div>
            </div>
            <div className='columns is-centered'>
              <div className='column'>
                <a
                  className='button is-primary is-outlined is-rounded is-medium'
                  href='#contact'
                >
                  <span className='icon'>
                    <i className='fab fa-dribbble'></i>
                  </span>
                  <span>Get Yours Done</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
