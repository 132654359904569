import Home from "./pages/Home"
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Redirect to="/"></Redirect>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
