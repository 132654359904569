import {Component} from "react"
import "./styles/Landing.css";
import Content from "./Landing/Content"

export default class Landing extends Component {
    constructor(props){
        super(props)
        this.state = {}
    }
    render() {
        return (
            <div className="Landing" id="landing">
                <Content/>
            </div>
        )
    }
}