import { Component } from 'react'
import './Content.css'

export default class Content extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return <div className='Content'></div>
  }
}
