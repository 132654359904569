import { Component } from 'react'
import './styles/Contact.css'
import Axios from 'axios'
import _ from 'lodash'

export default class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      full_name: '',
      address: '',
      email: '',
      message: '',
    }
  }
  sendMessage($event) {
    $event.preventDefault()
    if (
      _.isEmpty(this.state.full_name) ||
      _.isEmpty(this.state.address) ||
      _.isEmpty(this.state.email) ||
      _.isEmpty(this.state.message)
    ) {
      alert('Can You Fill all Fields Please?')
      return
    }
    let form = {
      full_name: this.state.full_name,
      address: this.state.address,
      email: this.state.email,
      message: this.state.message,
    }
    this.setState({
      full_name: '',
      address: '',
      email: '',
      message: '',
    })
    Axios.post(
      'https://sites-apis.herokuapp.com/apis/portfolio/client/send-message',
      form
    )
      .then((response) => {
        if (response.data.success) {
          alert('MESSAGE SENT SUCCESSFULLY. Thanks!')
        } else alert('ERROR: ' + response.data.message)
      })
      .catch((err) => alert('SORRY, SOMETHING IS WRONG!'))
  }
  inputChange(event) {
    let id = event.target.getAttribute('id')
    switch (id) {
      case 'email':
        this.setState({
          ...this.state,
          email: event.target.value,
        })
        break
      case 'message':
        this.setState({
          ...this.state,
          message: event.target.value,
        })
        break
      case 'address':
        this.setState({
          ...this.state,
          address: event.target.value,
        })
        break
      case 'full_name':
        this.setState({
          ...this.state,
          full_name: event.target.value,
        })
        break
      default:
        return
    }
  }
  render() {
    return (
      <div className='Contact' id='contact'>
        <h1>GET IN TOUCH</h1>
        <div className='contact__left'>
          <div className='form__container'>
            <form onSubmit={(e) => this.sendMessage(e)}>
              <input
                onChange={(e) => this.inputChange(e)}
                value={this.state.full_name}
                type='text'
                placeholder='Full Name'
                id='full_name'
                required
                minLength='4'
              />
              <input
                onChange={(e) => this.inputChange(e)}
                value={this.state.address}
                type='text'
                placeholder='Your Address'
                id='address'
                required
                minLength='5'
              />
              <input
                onChange={(e) => this.inputChange(e)}
                value={this.state.email}
                type='email'
                placeholder='Work Email'
                id='email'
                required
                minLength='8'
              />
              <textarea
                onChange={(e) => this.inputChange(e)}
                value={this.state.message}
                rows='6'
                placeholder='Write Your Message'
                id='message'
                required
                minLength='5'
              ></textarea>
              <button type='submit'>SEND</button>
            </form>
          </div>
        </div>
        <div className='contact__right'>
          <div className='content'>
            <p style={{ textAlign: 'justify' }}>
              Wanted your project to get from ideas to a final product or just
              send me a message and we will be glad to help you to cover your
              dreams. Feel free to let us know any of your feelings, ideas or
              suggestions, we will be there to respond as quick as possible.
            </p>
            <div className='contact__buttons'>
              <a className='linkedin' href='#'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  width='24'
                  height='24'
                >
                  <path fill='none' d='M0 0h24v24H0z' />
                  <path d='M6.94 5a2 2 0 1 1-4-.002 2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z' />
                </svg>
              </a>
              <a className='facebook' href='#'>
                <svg
                  className='icon__facebook'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  width='24'
                  height='24'
                >
                  <path fill='none' d='M0 0h24v24H0z' />
                  <path d='M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z' />
                </svg>
              </a>
              <a className='twitter' href='#'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  width='24'
                  height='24'
                >
                  <path fill='none' d='M0 0h24v24H0z' />
                  <path d='M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z' />
                </svg>
              </a>
              <a className='mail' href='#' title='Email'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  width='24'
                  height='24'
                >
                  <path fill='none' d='M0 0h24v24H0z' />
                  <path d='M22 20.007a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V19h18V7.3l-8 7.2-10-9V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v16.007zM4.434 5L12 11.81 19.566 5H4.434zM0 15h8v2H0v-2zm0-5h5v2H0v-2z' />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
