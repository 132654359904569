import { Component } from 'react'
import './styles/Services.css'

export default class Services extends Component {
  constructor(props) {
    super(props)
    this.state = {
      skills: {
        design_tools: [],
        dev_tools: [],
        mentor_stats: [],
      },
    }
  }

  render() {
    return (
      <div className='Services' id='services'>
        <section className='section is-medium is-info has-text-centered is-long'>
          <div className='container'>
            <div className='columns is-centered'>
              <div
                className='column is-three-fifths'
                style={{ marginTop: '-80px' }}
              >
                <h1 className='title is-spaced is-size-3-desktop is-size-4-mobile has-text-white'>
                  Hi, our people. Nice to meet you.
                </h1>
                <h2
                  className='subtitle is-size-5-desktop has-text-white'
                  style={{ textAlign: 'justify' }}
                >
                  Embrace the future of your organization with our tailored
                  digital solutions. We'll guide you through a seamless
                  transition, from paper to pixels, optimizing processes and
                  boosting efficiency. Our user-friendly software is designed to
                  suit your unique needs and budget, ensuring a smooth and
                  cost-effective digitization journey. Stay ahead of the
                  competition, reduce operational costs, and unlock new
                  opportunities. Let's digitize your organization together,
                  empowering growth and innovation in this digital age.
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section className='section skills has-text-centered'>
          <div className='container is-narrow'>
            <div className='box'>
              <div className='content'>
                <div className='columns is-centered'>
                  <div className='column'>
                    <figure className='image'>
                      <img
                        className='is-skill-icon'
                        src='./images/designer.svg'
                        alt='designer img'
                      />
                    </figure>
                    <h1 className='title is-size-4 is-spaced'>Designer</h1>
                    <p style={{ textAlign: 'justify' }}>
                      We value simple content structure, clean design patterns,
                      and thoughtful interactions.
                    </p>
                  </div>
                  <div className='column'>
                    <figure className='image'>
                      <img
                        className='is-skill-icon'
                        src='./images/frontend.svg'
                        alt='front end img'
                      />
                    </figure>
                    <h1 className='title is-size-4 is-spaced'>
                      Full-Stack Developer
                    </h1>
                    <p style={{ textAlign: 'justify' }}>
                      We like to code things from scratch, and enjoy bringing
                      ideas to life in the browser.
                    </p>
                  </div>
                  <div className='column'>
                    <figure className='image'>
                      <img
                        className='is-skill-icon'
                        src='./images/mentor.svg'
                        alt='mentor img'
                      />
                    </figure>
                    <h1 className='title is-size-4 is-spaced'>Mentor</h1>
                    <p style={{ textAlign: 'justify' }}>
                      We genuinely care about people, and love helping fellow
                      designers work on their craft.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <br />
      </div>
    )
  }
}
