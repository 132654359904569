import { Component } from 'react'
import './styles/About.css'

export default class About extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className='About' id='about'>
        <h1 className='about__head'>ABOUT US</h1>
        <div className='contact__right about-right'>
          <div className='content'>
            <h1>Who Are We?</h1>
            <div
              className='list-title has-text-weight-normal'
              style={{ textAlign: 'justify' }}
            >
              Our company stands at the forefront of technological innovation,
              dedicated to transforming your entire organization into a
              seamlessly digitized entity. Through our cutting-edge software
              solutions, we empower businesses to harness the full potential of
              the digital age. Our comprehensive suite of tools and services is
              designed to streamline processes, enhance productivity, and drive
              growth. From digitizing data management to automating workflows,
              we facilitate a smooth transition into the digital realm. Our
              user-friendly interfaces and intuitive applications ensure that
              even non-tech-savvy users can adapt effortlessly. We understand
              that each organization is unique, which is why our solutions are
              highly customizable to cater to your specific needs. Whether
              you're a small startup or a large enterprise, we have scalable
              options to suit your requirements and budget. By choosing us,
              you're not just investing in software; you're investing in the
              future of your organization. Embrace the digital revolution with
              confidence, and let us lead you towards a more efficient, agile,
              and technologically advanced future. Join us in shaping the
              digital world.
              <p>
                <a href='#contact'>Let's make something special.</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
