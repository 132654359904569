import { Component } from 'react'
import './NavBar.css'

export default class NavBar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  openMobileNavbar() {
    const navbar = document.getElementById('navbar')
    const navbarToggle = navbar.querySelector('.navbar-toggle')
    navbar.classList.add('opened')
    navbarToggle.setAttribute('aria-label', 'Close navigation menu.')
  }
  closeMobileNavbar() {
    const navbar = document.getElementById('navbar')
    const navbarToggle = navbar.querySelector('.navbar-toggle')
    navbar.classList.remove('opened')
    navbarToggle.setAttribute('aria-label', 'Open navigation menu.')
  }
  toggleNavBar() {
    const navbar = document.getElementById('navbar')
    if (navbar.classList.contains('opened')) {
      document.getElementById('navbar-links').style.backgroundColor =
        'transparent'
      this.closeMobileNavbar()
    } else {
      document.getElementById('navbar-links').style.backgroundColor = 'white'
      this.openMobileNavbar()
    }
  }
  navbarLinksContainerClick(clickEvent) {
    // clickEvent.stopPropagation();
  }
  render() {
    return (
      <div id='navbar'>
        <nav className='navbar-container'>
          <a href='/' className='home-link'>
            <div className='navbar-logo'></div>
          </a>
          <button
            type='button'
            onClick={() => this.toggleNavBar()}
            className='navbar-toggle'
            aria-label='Open navigation menu'
          >
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
          </button>
          <div
            className='Navbar-menu detached'
            id='navbar-menu'
            onClick={() => this.closeMobileNavbar()}
          >
            <ul
              className='navbar-links'
              id='navbar-links'
              onClick={() => this.navbarLinksContainerClick()}
            >
              <li className='navbar-item'>
                <a className='Navbar-link' href='#landing'>
                  Home
                </a>
              </li>
              <li className='navbar-item'>
                <a className='Navbar-link' href='#about'>
                  About
                </a>
              </li>
              <li className='navbar-item'>
                <a className='Navbar-link' href='#services'>
                  Services
                </a>
              </li>
              <li className='navbar-item'>
                <a className='Navbar-link' href='#projects'>
                  Projects
                </a>
              </li>
              <li className='navbar-item'>
                <a className='Navbar-link link__contact' href='#contact'>
                  Message Us
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    )
  }
}
