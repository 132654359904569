import { Component } from 'react'
import Landing from '../components/Landing'
import About from '../components/About'
import Services from '../components/Services'
import Projects from '../components/Projects'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
import './Home.css'

export default class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show_Down_button: true,
    }
  }
  componentDidMount() {
    let navBar = document.getElementById('navbar')
    navBar.style.height = '12vh'
    document.getElementById('body').addEventListener('scroll', (event) => {
      if (event.target.scrollTop > 0) {
        this.setState({
          ...this.state,
          show_Down_button: false,
        })
        navBar.style.transitionDuration = '0.5s'
        navBar.style.height = '9vh'
        navBar.style.boxShadow = '0 1px 4px rgba(0, 0, 0, 0.2)'
      } else {
        this.setState({
          ...this.state,
          show_Down_button: true,
        })
        navBar.style.height = '12vh'
        navBar.style.boxShadow = 'none'
        navBar.style.transitionDuration = '0.5s'
      }
    })
  }
  render() {
    return (
      <div className='Home' id='Home'>
        <NavBar />
        <Landing></Landing>
        <About></About>
        <Services></Services>
        <Projects></Projects>
        <Contact></Contact>
        <Footer></Footer>
      </div>
    )
  }
}
